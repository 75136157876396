var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"table-card"},[_c('v-card-title',[_c('v-row',{staticClass:"d-flex justify-space-between align-center mb-2 px-2"},[(!_vm.client)?_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"elevation-6 ms-1",attrs:{"size":"100","color":_vm.color}},[_c('v-icon',{attrs:{"dark":"","size":"64"}},[_vm._v(" $devices ")])],1),_c('div',{staticClass:"table-card-title ms-5 text-h2"},[_vm._v(" "+_vm._s(_vm.$phraser.set('all devices'))+" ")])],1):_vm._e(),_c('v-text-field',{staticClass:"d-flex table-search",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{class:_vm.clickable ? 'clickable' : '',attrs:{"headers":_vm.headers,"items":_vm.devices,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.$phraser.set('loading devices') + '...',"custom-filter":_vm.searchDevices,"sort-by":"data.received","sort-desc":true},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.identifier",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-h5 font-weight-bold purple--text"},[_vm._v(" "+_vm._s(item.identifier)+" ")])]}},{key:"item.slug",fn:function(ref){
var item = ref.item;
return [_c('small',[_vm._v(" "+_vm._s(item.slug)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',{class:!isNaN(item.service) ? _vm.services[item.service].text_color : _vm.services[3].text_color},[_c('v-icon',{attrs:{"color":!isNaN(item.service) ? _vm.services[item.service].color : _vm.services[3].color},domProps:{"textContent":_vm._s(!isNaN(item.service) ? _vm.services[item.service].icon : _vm.services[3].icon)}}),_vm._v(" "+_vm._s(_vm.services[item.service].text)+" ")],1)]}},{key:"item.sensors",fn:function(ref){
var item = ref.item;
return [(item.settings && item.settings.sensor_type)?_c('span',[_c('sensors-icons',{attrs:{"type":item.settings.sensor_type}})],1):_vm._e()]}},{key:"item.battery",fn:function(ref){
var item = ref.item;
return [(item.battery)?_c('span',[_c('v-icon',{attrs:{"color":item.battery.color},domProps:{"textContent":_vm._s(item.battery.icon)}}),_vm._v(" "+_vm._s(_vm.$t(item.battery.text))+" ")],1):_vm._e()]}},{key:"item.data.received",fn:function(ref){
var item = ref.item;
return [(item.data && item.data.received)?_c('strong',{class:item.online ? 'success--text' : 'error--text'},[_vm._v(" "+_vm._s(_vm.formatTime(item.data.received, 'MMMM Do YYYY, HH:mm:ss'))+" ")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }