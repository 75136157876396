<template>
  <div class="">
    <v-icon
      v-for="(icon, index) in icons[type]"
      :key="index"
      :color="colors[icon]"
      v-text="icon"
    />
  </div>
</template>

<script>
export default {
  name: 'SensorsIcons',

  props: {
    type: {
      type: Number,
      default: null,
    },
  },

  data: () => ({
    icons: {
      null: ['mdi-minus'],
      1: ['mdi-thermometer'],
      2: ['mdi-thermometer', 'mdi-water-percent'],
      6: ['mdi-thermometer', 'mdi-water-percent', 'mdi-lightbulb-on'],
      7: ['mdi-lightbulb-on'],
      8: ['mdi-motion-sensor'],
      9: ['mdi-smoke-detector'],
      10: ['mdi-water'],
      11: ['mdi-magnet-on'],
      12: ['mdi-door'],
      13: ['mdi-window-open-variant'],
    },
    colors: {
      'mdi-thermometer': 'light-blue darken-1',
      'mdi-water-percent': 'cyan darken-1',
      'mdi-lightbulb-on': 'yellow darken-3',
      'mdi-motion-sensor': 'deep-orange lighten-1',
      'mdi-smoke-detector': 'blue-grey lighten-2',
      'mdi-water': 'blue darken-2',
      'mdi-magnet-on': 'red lighten-1',
      'mdi-door': 'deep-purple',
      'mdi-window-open-variant': 'deep-purple',
    },
  }),

  mounted () {
    // console.log('SensorsIcons', this.type)
  },

  // methods: {
  // },
}
</script>
