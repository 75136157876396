<template>
  <v-container>
    <v-card class="table-card">
      <v-card-title>
        <v-row class="d-flex justify-space-between align-center mb-2 px-2">
          <div v-if="!client" class="d-flex">
            <v-avatar size="100" class="elevation-6 ms-1" :color="color">
              <v-icon dark size="64">
                $devices
              </v-icon>
            </v-avatar>
            <div class="table-card-title ms-5 text-h2">
              {{ $phraser.set('all devices') }}
            </div>
          </div>
          <v-text-field
            v-model="search"
            class="d-flex table-search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
          />
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="devices"
        :search="search"
        :loading="loading"
        :loading-text="$phraser.set('loading devices') + '...'"
        :class="clickable ? 'clickable' : ''"
        :custom-filter="searchDevices"
        sort-by="data.received"
        :sort-desc="true"
        @click:row="handleClick"
      >
        <template v-slot:item.identifier="{ item }">
          <span class="text-h5 font-weight-bold purple--text">
            {{ item.identifier }}
          </span>
        </template>
        <template v-slot:item.slug="{ item }">
          <small>
            {{ item.slug }}
          </small>
        </template>
        <template v-slot:item.type="{ item }">
          <span :class="!isNaN(item.service) ? services[item.service].text_color : services[3].text_color">
            <v-icon
              :color="!isNaN(item.service) ? services[item.service].color : services[3].color"
              v-text="!isNaN(item.service) ? services[item.service].icon : services[3].icon"
            />
            {{ services[item.service].text }}
          </span>
        </template>
        <template v-slot:item.sensors="{ item }">
          <span v-if="item.settings && item.settings.sensor_type">
            <sensors-icons :type="item.settings.sensor_type" />
          </span>
        </template>
        <!-- <template v-slot:item.battery="{ item }">
          <span v-if="item.data.battery" :color="item.data.battery.color">
            <v-icon
              :color="item.data.battery.color"
              v-text="item.data.battery.icon"
            />
            <strong>
              {{ $t(item.data.battery.text) }}
            </strong>
          </span>
        </template> -->
        <!-- <template v-slot:item.online="{ item }">
          <span
            :class="
              item.online
                ? 'success--text font-weight-bold'
                : 'error--text font-weight-bold'
            "
          >
            {{ item.online ? $phraser.set('online') : $phraser.set('offline') }}
          </span>
        </template> -->
        <template v-slot:item.battery="{ item }">
          <span v-if="item.battery">
            <v-icon
            :color="item.battery.color"
            v-text="item.battery.icon"
            />
            {{ $t(item.battery.text) }}
          </span>
          <!-- {{ setBattery(item.data.battery.value) }} -->
        </template>
        <template v-slot:item.data.received="{ item }">
          <strong v-if="item.data && item.data.received" :class="item.online ? 'success--text' : 'error--text'">
            <!-- {{ item.data.received }} -->
            {{ formatTime(item.data.received, 'MMMM Do YYYY, HH:mm:ss') }}
          </strong>
        </template>
        <!-- <template v-slot:item.updated_at="{ item }">
          {{ formatTime(item.updated_at) }}
        </template> -->
        <!-- <template v-slot:item.created_at="{ item }">
          {{ formatTime(item.created_at) }}
        </template> -->
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment'
import SetBatteryMixin from '@/mixins/SetBatteryMixin'
import SensorsIcons from '@/views/dashboard/components/SensorsIcons'
export default {
  name: 'Devices',

  components: { SensorsIcons },
  mixins: [SetBatteryMixin],

  props: {
    client: {
      type: Number,
      default: null,
    },
  },

  data: () => ({
    icon: 'mdi-router-wireless',
    color: 'purple',
    // now: null,
    // timeInterval: null,
    clickable: true,
    search: '',
    headers: [],
    // colors: [
    //   'blue--text text--darken-4',
    //   'light-blue--text text--darken-3',
    //   'cyan--text text--darken-3',
    // ],
    services: [
      { text: 'BLE', icon: 'mdi-bluetooth', color: 'light-blue darken-3', text_color: 'blue--text text--darken-4' },
      { text: 'WiFi', icon: 'mdi-wifi', color: 'blue darken-4', text_color: 'light-blue--text text--darken-3' },
      { text: 'סלולרי', icon: 'mdi-signal-cellular-3', color: 'cyan darken-3', text_color: 'cyan--text text--darken-3' },
      { text: 'לא נתמך', icon: '', color: 'red', text_color: 'red' },
    ],
  }),

  computed: {
    users () {
      if (this.client) {
        return this.$store.getters['users/byClient'](this.client)
      }
      return null
    },
    usersDevices () {
      const loading = this.$store.getters['usersDevices/loading']
      if (!this.users && !loading) return []
      const devices = this.$store.getters['usersDevices/getByUsers'](this.users)
      return devices
    },
    devices () {
      const devices = this.$store.getters['devices/devices']
      let devicesArray = []
      Object.keys(devices).map(identifier => {
        devices[identifier].identifier = identifier
        if (devices[identifier].data && devices[identifier].data.battery) {
          devices[identifier].battery = this.setBattery(devices[identifier].data.battery.value)
        }
        devicesArray.push(devices[identifier])
      })
      if (this.client) {
        devicesArray = devicesArray.filter(device => {
          return this.usersDevices.includes(device.identifier)
        })
      }
      return devicesArray
    },
    loading () {
      return this.$store.getters['devices/loading']
    },
  },

  created () {
    moment.locale(this.$i18n.locale)
  },
  mounted () {
    this.setHeaders()
    // console.log('devices', this.devices)
  },

  methods: {
    handleClick (device) {
      if (this.clickable) {
        this.$router.push({ name: 'Device', params: { slug: device.slug } })
      }
    },
    searchDevices (value, search, item) {
      let searchIn = [item.identifier]
      if (item.slug) {
        searchIn = [
          item.identifier,
          item.slug,
          this.services[item.service].text,
        ]
      }
      return searchIn.findIndex(v => {
          return (v.toLowerCase()).includes(search.toLowerCase())
      }) >= 0
    },
    setHeaders () {
      this.headers = [
        {
          text: this.$t('identifier'),
          align: 'start',
          sortable: false,
          value: 'identifier',
        },
        // { text: this.$phraser.set('slug'), value: 'slug' },
        { text: this.$phraser.set('type'), value: 'type' },
        { text: 'חיישנים', value: 'sensors', sortable: false },
        { text: this.$phraser.set('battery'), value: 'battery', sortable: false },
        // { text: this.$phraser.set('status'), value: 'online' },
        { text: this.$phraser.set('last_communication'), value: 'data.received' },
        // { text: this.$phraser.set('updated at'), value: 'updated_at' },
        // { text: this.$phraser.set('created at'), value: 'created_at' },
      ]
    },
    formatTime (time, format = 'lll') {
      if (!isNaN(time) && String(time).length === 10) time = time * 1000
      return moment(time)._isValid ? moment(time).format(format) : time
    },
  },
}
</script>
