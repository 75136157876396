export default {
  methods: {
    setBattery (data) {
      if (!data) return null
      let battery
      if (typeof data === 'object') {
        battery = data.original
      } else {
        battery = parseFloat(data)
      }
      const batteryObj = {
        text: battery,
        icon: 'mdi-battery',
        color: 'black',
        original: battery,
      }
      if (battery === 1) {
        batteryObj.text = 'full_battery'
        batteryObj.icon = 'mdi-battery'
        batteryObj.color = 'green darken-4'
      } else if (battery < 1) {
        batteryObj.text = 'empty_battery'
        batteryObj.icon = 'mdi-battery-alert-variant-outline'
        batteryObj.color = 'red accent-4'
      } else if (battery > 9) {
        batteryObj.text = parseInt(battery) + '%'
        batteryObj.icon = 'mdi-battery'
        batteryObj.color = 'green darken-4'
        if (battery < 100) {
          const rounded = parseInt(battery / 10) * 10
          batteryObj.icon += '-' + rounded
          switch (true) {
            case rounded < 10:
              batteryObj.color = 'red accent-4'
              break
            case rounded < 20:
              batteryObj.color = 'red lighten-2'
              break
            case rounded < 40:
              batteryObj.color = 'orange darken-1'
              break
            case rounded < 50:
              batteryObj.color = 'lime darken-2'
              break
            case rounded < 60:
              batteryObj.color = 'light-green darken-2'
              break
            case rounded < 80:
              batteryObj.color = 'green lighten-1'
              break
          }
        }
      } else if (battery < 9) {
        batteryObj.text = battery + 'V'
        switch (true) {
          case battery >= 3.05:
            batteryObj.icon = 'mdi-battery'
            batteryObj.color = 'green darken-4'
            break
          case battery > 2.9:
            batteryObj.icon = 'mdi-battery-90'
            batteryObj.color = 'green lighten-1'
            break
          case battery > 2.8:
            batteryObj.icon = 'mdi-battery-80'
            batteryObj.color = 'light-green darken-2'
            break
          case battery > 2.7:
            batteryObj.icon = 'mdi-battery-60'
            batteryObj.color = 'lime darken-2'
            break
          case battery > 2.6:
            batteryObj.icon = 'mdi-battery-30'
            batteryObj.color = 'orange darken-1'
            break
          case battery <= 2.6:
            batteryObj.icon = 'mdi-battery-alert-variant-outline'
            batteryObj.color = 'red accent-4'
            break
          default:
        }
      }
      return batteryObj
    },
  },
}
